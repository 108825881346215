import React from 'react';
import { graphql } from 'gatsby';
import { Clock } from '@styled-icons/evil/Clock';

import {
  HeadingXL,
  Layout,
  SEO,
  TextBody,
  TextMuted,
  HeaderLogo,
} from '../components';
import { AuthorsSmall } from '../components/AuthorsSmall';
import { Share } from '../components/Share';
import { Epigraph } from '../components/Epigraph';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container } from '@chakra-ui/layout';

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;
  const { title, date, epigraph, authors, featuredImage, headingImage } =
    post.frontmatter;

  return (
    <>
      <SEO title={title} featuredImage={featuredImage} />
      <HeaderLogo />
      <Layout>
        <Container maxW="900px">
          <HeadingXL>
            {headingImage ? (
              <GatsbyImage image={getImage(headingImage)} />
            ) : (
              title
            )}
          </HeadingXL>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextMuted>Publicado el {date}</TextMuted>
            <TextMuted style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Clock height="16" width="16" style={{ marginRight: '4px' }} />
              {Math.round(post.fields.readingTime.minutes)} minutos
            </TextMuted>
          </div>
          <div style={{ margin: '8px 0' }}>
            <AuthorsSmall key={authors.id} authors={authors} />
          </div>
          {epigraph && <Epigraph>{epigraph}</Epigraph>}
          <TextBody dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr />
          <Share title={post.frontmatter.title} />
        </Container>
      </Layout>
    </>
  );
};

export default BlogPost;

export const query = graphql`
  query ($id: String) {
    markdownRemark(id: { eq: $id }) {
      ...Post
    }
  }
`;
