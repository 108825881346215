import React from 'react';
import { kebabCase } from 'lodash';
import { ScTwitter } from '@styled-icons/evil/ScTwitter';
import { ScFacebook } from '@styled-icons/evil/ScFacebook';
import { ScLinkedin } from '@styled-icons/evil/ScLinkedin';
import { Whatsapp as WhatsappIcon } from '@styled-icons/icomoon/Whatsapp';
import { BREAKPOINT } from '../utils/constants';
import styled from 'styled-components';

const PurpleCircleButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--accent-color);
  color: white;
`;

const SocialLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  @media (min-width: ${BREAKPOINT}px) {
    display: flex;
  }
`;

const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-column-gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  @media (min-width: ${BREAKPOINT}px) {
    margin-top: 0;
    margin-left: 8px;
  }
`;

export function Share({ title }) {
  return (
    <Wrapper>
      <div>Si te gustó este artículo compartilo en:</div>
      <Links>
        <SocialLink
          href="https://twitter.com/share?ref_src=twsrc%5Etfw"
          target="__blank"
          rel="noopener"
        >
          <PurpleCircleButton>
            <ScTwitter height="32" width="32" />
          </PurpleCircleButton>
        </SocialLink>
        <SocialLink
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            `https://perspectivafeminista.com.ar/${kebabCase(title)}`,
          )}`}
          target="__blank"
          rel="noopener noreferrer"
        >
          <PurpleCircleButton>
            <ScFacebook height="32" width="32" />
          </PurpleCircleButton>
        </SocialLink>
        <SocialLink
          href={`http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            `https://perspectivafeminista.com.ar/${kebabCase(title)}`,
          )}&title=${encodeURIComponent(
            title,
          )}&source=perspectivafeminista.com.ar`}
          target="__blank"
          rel="noopener noreferrer"
        >
          <PurpleCircleButton>
            <ScLinkedin height="32" width="32" />
          </PurpleCircleButton>
        </SocialLink>
        <SocialLink
          href={`https://web.whatsapp.com/send?text=${encodeURIComponent(
            `https://perspectivafeminista.com.ar/${kebabCase(title)}`,
          )}`}
          target="__blank"
        >
          <PurpleCircleButton style={{ height: '32px', width: '32px' }}>
            <WhatsappIcon height="20" width="20" />
          </PurpleCircleButton>
        </SocialLink>
      </Links>
    </Wrapper>
  );
}
